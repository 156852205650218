<template>
  <!-- <v-container> -->
  <v-app-bar
    app
    color="white"
    flat
    class="appbar--box--shadow"
    id="appbar--box--shadow"
  >
    <!-- <v-app-bar app flat class="appbar--box--shadow"> -->
    <img
      alt="Avatar"
      class="appbar--avatar"
      src="../assets/logo.png"
      @click="goHome"
    />
    <v-spacer></v-spacer>
    <div v-if="isLogedin">
      <v-btn
        class="text--regular--14 hidden-sm-and-down"
        @click="onChangeRoute(link.route)"
        v-for="link in links"
        :key="link.id"
        text
      >
        <img
          :src="getImgUrl(link.icon)"
          width="17"
          class="pr-1 hidden-sm-and-down"
          :alt="link.title"
        />

        {{ link.title }}
      </v-btn>
    </div>
    <div v-else class="appbar--backto-mainweb">
      <p
        class="ma-0 text--regular--14 appbar--backto-mainweb--text"
        @click="gotoMainWebsite"
      >
        <v-icon style="color: #282828;">mdi-chevron-left</v-icon
        >กลับสู่เว็บไซต์หลัก RCAC84
      </p>
    </div>
    <v-app-bar-nav-icon
      class="hidden-md-and-up hidden-lg-and-up hidden-xl-and-up"
      @click.stop="drawer = !drawer"
    >
    </v-app-bar-nav-icon>
    <v-menu
      class="hidden-md-and-up hidden-lg-and-up hidden-xl-and-up appbar--vmenu--custom"
      v-model="drawer"
      :absolute="drawer"
      :attach="'#app'"
      :content-class="'elevation-0'"
      min-width="100%"
      v-if="drawer"
    >
      <div class="d-flex flex-column  justify-start">
        <v-list>
          <v-list-item class="d-flex justify-end">
            <v-btn icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list v-for="(link, i) in links" :key="i.id">
          <v-list-item @click="onChangeRoute(link.route)">
            <v-list-item-content>
              <v-list-item-title class="text--medium--16">{{
                link.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="links.indexOf(link) !== links.length - 1"
            class="mt-2 appbar--v-divider"
          ></v-divider>
        </v-list>
      </div>
    </v-menu>
    <v-dialog v-model="removeDialog" max-width="780" :retain-focus="false">
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            ระบบจะทำการลบข้อมูลการจองที่ท่านเลือกไว้ ท่านต้องการจะทำต่อหรือไม่
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <v-btn
            elevation="0"
            class="popup-comfirm-delete-button mb-2"
            outlined
            rounded
            color="black"
            @click="removeAreaCart()"
            width="200"
          >
            <p class="mb-0 black--text">
              ตกลง
            </p>
          </v-btn>
          <v-btn
            elevation="0"
            rounded
            class="popup-comfirm-delete-button mb-2"
            color="primary"
            @click="removeDialog = false"
            width="200"
          >
            <p class="mb-0 black--text ">
              ยกเลิก
            </p>
          </v-btn>
        </v-card-actions>
        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="removeDialog = false"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-app-bar>
  <!-- </v-container> -->
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isLogedin: state => {
        return state.authentication.loggedIn;
      },
      areaCart: state => {
        return state.areaspecify.areaCart;
      }
    })
  },
  data: () => ({
    links: [
      {
        title: "ขอใช้พื้นที่",
        icon: "appbar-area.svg",
        id: 1,
        route: "rentspacecriteria"
      },
      {
        title: "บัญชีโปรไฟล์",
        icon: "appbar-user.svg",
        id: 2,
        route: "profile"
      },
      {
        title: "ประวัติคำขอใช้พื้นที่",
        icon: "appbar-history.svg",
        id: 3,
        route: "history"
      },
      {
        title: "ออกจากระบบ",
        icon: "appbar-logout.svg",
        id: 4,
        route: "logout"
      }
    ],
    drawer: false,
    removeDialog: false, // Start : Sasiprapa B. : 2021-04-24
    currentRoute: null // Start : Sasiprapa B. : 2021-04-24
  }),
  methods: {
    ...mapActions("areaspecify", [
      "getAreaCart",
      "setAreaCardSelected",
      "setAreaConfirmationSelected" //Start : Sasiprapa B. 2021-04-24
    ]),
    // Start : Sasiprapa B.: 2021-04-25
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    // End : Sasiprapa B.: 2021-04-25
    ...mapActions("authentication", ["logout"]),
    onChangeRoute(route) {
      this.currentRoute = route;
      // if (
      //   route === "rentspacecriteria" &&
      //   this.$route.name === "bookingprocess"
      // ) {
      //   // this.validatePopup();
      //   // this.setRouter();
      // } else {
      //   if (route === "logout") {
      //     this.logout();
      //   } else {
      //     this.$router.push(`/${route}`);
      //   }
      //   // clear background
      //   this.setBackgroundMain();
      // }

      if (route === "logout") {
        window.FB.api("/me/permissions", "delete", null, () =>
          window.FB.logout()
        );
        this.logout();
        this.setBackgroundMain(); // set Bg
      } else {
        this.$router.push(`/${route}`);
        this.setBackgroundMain(); // set Bg
      }
    },
    getImgUrl(imageName) {
      return require(`../assets/icons/${imageName}`);
    },
    goHome() {
      // this.$router.push("/");
    },
    gotoMainWebsite() {
      window.location.href = "https://rcac84.com";
    },
    // validatePopup() {
    //   if (this.areaCart.length > 0) {
    //     this.removeDialog = true;
    //   } else {
    //     this.removeDialog = false;
    //     this.setRouter();
    //   }
    // },
    removeAreaCart() {
      //clear data
      this.setAreaConfirmationSelected([]);
      this.setAreaCardSelected([]);
      this.removeDialog = false;
      this.setRouter();
    },
    setRouter() {
      if (this.currentRoute === "logout") {
        this.logout();
      } else {
        this.$router.push(`/${this.currentRoute}`);
      }
      // clear background
      this.setBackgroundMain();
    },
    // Start : Sasiprapa B.: 2021-04-25
    setBackgroundMain() {
      // set 0 : clear stepper
      this.setBackgroundFormStepperScreen(0);
    }
    // End : Sasiprapa B.: 2021-04-25
  }
};
</script>

<style scoped>
.appbar--box--shadow {
  box-shadow: 0 4px 2px -2px #f7f7f7 !important;
  height: 83px !important;
  /* UI Properties */
  /* box-shadow: 0px 5px 12px #0000000a !important;
  background: #ffffff 0% 0% no-repeat !important;
  opacity: 1 !important;
  height: 83px !important; */
  /* z-index: 9; */
  /* Lay Out */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  */
}

.appbar--v-divider {
  max-width: 100% !important;
}

@media (max-width: 480px) {
  .appbar--avatar {
    width: 99px;
    height: auto;
    cursor: pointer;
    margin-left: 0px;
  }

  .appbar--backto-mainweb {
    display: none;
  }
  .appbar--box--shadow {
    height: 67px !important;
  }
}

@media (min-width: 481px) {
  .appbar--avatar {
    width: 89px;
    height: auto;
    cursor: pointer;
    margin-left: 42px;
  }

  .appbar--backto-mainweb {
    margin-right: 98px;
  }

  .appbar--backto-mainweb--text {
    cursor: pointer;
  }

  .appbar--box--shadow {
    box-shadow: 0 4px 2px -2px #f7f7f7 !important;
  }

  .confirmation-popup-title {
    display: flex;
    justify-content: center;
  }

  .confirmation--closeicon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .confirmation-popup-card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px;
  }

  .confirmation-popup-title {
    display: flex;
    justify-content: center;
  }

  .popup-comfirm-delete-text {
    font-weight: 700;
    font-size: 21px;
  }

  .popup-comfirm-delete-button {
    font-weight: 500;
    font-size: 14px;
  }

  .popup-comfirm-delete {
    display: flex;
    justify-content: center;
  }
}
</style>
