const state = () => ({
  confirmMationItem: []
});

const actions = {
  submitConfirmMation({ commit }, param) {
    commit("submitConfirmMation", param);
  }
};

const mutations = {
  submitConfirmMation(state, payload) {
    state.confirmMationItem = payload;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
