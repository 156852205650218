import axios from "axios";

export default {
  async sendMail(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/sendmail`, param);
      return response;
    } catch (error) {
      return null;
    }
  },
  async sendMailWelcome(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/welcomemail`, param);
      return response;
    } catch (error) {
      return null;
    }
  },
  async sendEMailActivateProfile(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/activate`, param);
      return response;
    } catch (error) {
      return null;
    }
  },
  async sendEMailUserCancel(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/usercancel`, param);
      return response;
    } catch (error) {
      return null;
    }
  }
};
