import login from "../../api/login";
import { router } from "../../router";

const state = () => ({
  isLogged: false
});

const getters = {};

// actions
const actions = {
  checkLogin({ commit }, param) {
    login.checkLogin(param, () => {
      commit("setCheckLoginStatus", true);
    });
  }
};

const mutations = {
  setCheckLoginStatus(state, status) {
    state.isLogged = status;
    router.push("/rentspacecriteria");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
