import axios from "axios";

export default {
  async getAvailableArea(filters) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/rooms?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/rooms`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
};
