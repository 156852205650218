import regulationsApi from "../../api/regulations";

const state = () => ({
  regulationsData: null
});

const getters = {
  getDocType: state => {
    if (state.regulationsData) {
      return state.regulationsData.filter(o => o.doc_type === "doc");
    } else {
      return null;
    }
  },
  getImageType: state => {
    if (state.regulationsData) {
      return state.regulationsData.filter(o => o.doc_type === "image");
    } else {
      return null;
    }
  }
};

// actions
const actions = {
  async initPage({ commit, dispatch }, filter) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await regulationsApi.initPage(filter);
    commit("initPage", result);
    dispatch("app/setOverlayState", false, { root: true });
  }
};

// mutations
const mutations = {
  initPage(state, result) {
    if (result) {
      if (result.status === 200) {
        state.regulationsData = result.data;
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
