import userApi from "../../api/user";

const state = () => ({
  userRegister: null,
  userProfile: null,
  userFullName: localStorage.getItem("fullname")
});

const getters = {};

const actions = {
  async onSignup(state, form) {
    return await userApi.signup(form);
  },
  async getUserProfile({ commit }) {
    let result = await userApi.getUserProfile();
    commit("setUserProfile", result);
  },
  async updateProfile({ commit, dispatch }, form) {
    dispatch("app/setOverlayState", true, { root: true });
    let result = await userApi.updateUserProfile(form);
    commit("setUserProfile", result);
    dispatch("getUserProfile");
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async getFullName({ commit, dispatch }) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await userApi.getFullName();
    commit("setFullName", response);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async activateProfile({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    await userApi.activateProfile(param);
    localStorage.removeItem("keeplink");
    dispatch("app/setOverlayState", false, { root: true });
  },
  async forgotPassword({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await userApi.onForgotPassword(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  },
  async resetPassword({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await userApi.onResetPassword(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  }
};

// mutations
const mutations = {
  setUserProfile(state, result) {
    if (result) {
      if (result.status === 200) {
        state.userProfile = result.data;
      }
    }
  },
  setFullName(state, response) {
    if (response) {
      if (response.status === 200) {
        state.userFullName = response.data.fullname || response.data.email;
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
