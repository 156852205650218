// import area from "../../api/area";
// import router from "../../router";

const state = () => ({
  startDate: null,
  endDate: null,
  areaLists: null,
  isOneDaySelected: false,
  isSubmitDate: false
});

const getters = {};

const actions = {
  resetStateData({ commit }) {
    commit("resetStateData");
  },
  submitDate({ commit }, param) {
    commit("setDateSpecifyState", param);
  },
  setOnSubmitDate({ commit }, param) {
    commit("setOnSubmitDate", param);
  }
};

const mutations = {
  resetStateData(state) {
    Object.assign(state, {
      startDate: null,
      endDate: null,
      areaLists: null,
      isOneDaySelected: false,
      isSubmitDate: false
    });
  },
  setAvailableAreas(state, payload) {
    state.areaLists = payload;
  },
  setDateSpecifyState(state, payload) {
    state.startDate = payload.startDate;
    state.endDate = payload.endDate;
    state.isOneDaySelected = payload.isOneDaySelected;
  },
  setOnSubmitDate(state, payload) {
    state.isSubmitDate = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
