const state = () => ({
  overlay: false,
  screenStepper: 5, //Start : Sasiprapa B. : 2021-04-23
  backgroundcolor: "white" //Start : Sasiprapa B. : 2021-04-23
});

const actions = {
  setOverlayState({ commit }, overlayState) {
    commit("setOverlayState", overlayState);
  },
  // Start :  Sasiprapa B. : 2021-04-25
  setBackgroundFormStepperScreen({ commit }, param) {
    commit("setBackgroundFormStepperScreen", param);
  }
  // End :  Sasiprapa B. : 2021-04-25
};

const mutations = {
  setOverlayState(state, overlayState) {
    state.overlay = overlayState;
  },
  // Start :  Sasiprapa B. : 2021-04-25
  setBackgroundFormStepperScreen(state, item) {
    state.screenStepper = item;
    if (state.screenStepper === 1) {
      state.backgroundcolor = "#F7F7F8";
    } else if (
      state.screenStepper === 2 ||
      state.screenStepper === 3 ||
      state.screenStepper === 4
    ) {
      state.backgroundcolor = "#FAFAFA";
    } else {
      state.backgroundcolor = "white";
    }
  }
  // End :  Sasiprapa B. : 2021-04-25
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
