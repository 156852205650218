const state = () => ({
  organizationsTypeSelected: null,
  requireDocList: null
});

const getters = {};

const actions = {
  async setOrganizationsType({ commit }, param) {
    commit("setOrganizationsType", param);
  },
  async setRequireDoc({ commit }, param) {
    commit("setRequireDoc", param);
  }
};

const mutations = {
  setOrganizationsType(state, param) {
    if (param) {
      state.organizationsTypeSelected = param;
    }
  },
  setRequireDoc(state, param) {
    if (param) {
      state.requireDocList = [...param];
    } else {
      state.requireDocList = null;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
