import axios from "axios";

export default {
  async signup(form) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/signup`, form);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async getUserProfile() {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.get(`${baseUrl}/whoAmI`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async updateUserProfile(updateForm) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.patch(
        `${baseUrl}/update_profile`,
        updateForm
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async getFullName() {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.get(`${baseUrl}/fullname`);
      if (response.status === 200) {
        localStorage.setItem(
          "fullname",
          response.data.fullname || response.data.email
        );
      }
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async activateProfile(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.patch(`${baseUrl}/activateprofile`, param);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async onForgotPassword(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/forgotPassword`, param);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async onResetPassword(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(
        `${baseUrl}/users/resetpassword`,
        param
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
};
