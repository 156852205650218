<template>
  <v-container>
    <v-row>
      <v-col class="home-regulations-padding">
        <Regulations />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="loginform-padding" v-if="isLoginForm">
        <LoginForm @clicked="onHandleForm" />
      </v-col>
      <v-col class="registerform-padding" v-if="!isLoginForm">
        <RegisterForm @clicked="onHandleForm" />
      </v-col>
    </v-row>
  </v-container>
  <!-- <v-container>
    <v-row>
      <v-col >
        <Regulations />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-8" v-if="isLoginForm">
        <LoginForm @clicked="onHandleForm" />
      </v-col>
      <v-col class="pa-8" v-if="!isLoginForm">
        <RegisterForm @clicked="onHandleForm" />
      </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import Regulations from "@/components/Regulations.vue";

export default {
  name: "home",
  components: {
    LoginForm,
    Regulations,
    RegisterForm
  },
  data: function() {
    return {
      isLoginForm: true
    };
  },
  methods: {
    onHandleForm(event) {
      this.isLoginForm = event === "login";
    }
  }
};
</script>
<style scoped>
.loginform-padding {
  padding: 37px 0px 0px 49.5px;
}
.registerform-padding {
  padding: 37px 0px 0px 50.5px;
}
.home-regulations-padding {
  padding: 31px 15px 0px 15px;
}
/* mobile */
@media (max-width: 959px) {
  .loginform-padding {
    padding: 28px 15px 61px 15px;
  }
  .registerform-padding {
    padding: 28px 15px 62.5px 15px;
  }
}
/* Destop */
@media (min-width: 960px) {
  .loginform-padding {
    padding: 37px 0px 0px 49.5px;
  }
  .registerform-padding {
    padding: 37px 0px 0px 50.5px;
  }
}
</style>
