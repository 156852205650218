<template>
  <div>
    <p class="mb-0 text-center text--regular--14">
      ยังไม่เคยมีบัญชีในระบบ
      <span
        class="primary--text text--regular--14 loginform-booking loginform--title"
        @click="toRegisterForm"
        >สมัครสมาชิก</span
      >
    </p>
    <p
      class="mb-0 loginform-booking loginform-reserve-space-padding text--bold--18"
    >
      เข้าสู่ระบบการจองพื้นที่
    </p>
    <p class="mb-0 text--regular--14 loginform-booking">
      โปรดอ่านกฏระเบียบในการจองพื้นที่เพื่อทราบถึงข้อมูลที่จำเป็น
    </p>
    <v-form
      @submit="validate"
      ref="form"
      v-model="valid"
      onSubmit="return false;"
      lazy-validation
      class="loginform-gmail-padding"
    >
      <p class="mb-0 text--bold--14">อีเมล</p>
      <v-text-field
        :rules="emailRules"
        v-model="form.email"
        label=""
        single-line
        outlined
        required
        dense
        class="loginform-gmail-text-field"
      ></v-text-field>

      <div
        class="loginfrom-password-mode-mobile hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
      >
        <p class="mb-0  loginform-password" @click="validate">รหัสผ่าน</p>
        <p
          class="mb-0 loginform-forgot-password"
          @click="onClickForgotPassword"
        >
          ลืมรหัสผ่าน
        </p>
      </div>
      <p class="mb-0  loginform-password hidden-xs-only" @click="validate">
        รหัสผ่าน
      </p>
      <v-text-field
        v-model="form.password"
        label=""
        single-line
        outlined
        required
        :rules="passwordRules"
        dense
        class="loginform-gmail-text-field"
        :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="visiblePassword ? 'text' : 'password'"
        @click:append="visiblePassword = !visiblePassword"
      ></v-text-field>
      <div
        style="
    display: flex;
    justify-content: flex-end;
"
      >
        <p
          class="mb-0 loginform-forgot-password hidden-xs-only"
          @click="onClickForgotPassword"
        >
          ลืมรหัสผ่าน
        </p>
      </div>

      <div v-if="loginState && isShowErrorMessage">
        <p
          class="mb-0 red--text text--regular--14"
          v-if="
            (loginState.statusCode === 401 || loginState.statusCode === 422) &&
              loginState.message !== 'email not found' &&
              loginState.message !== 'Invalid email or password.' &&
              loginState.message !==
                'The request body is invalid. See error object `details` property for more info.'
          "
        >
          {{ loginState.message }}
        </p>
        <p
          class="mb-0 red--text text--regular--14"
          v-if="
            ((loginState.statusCode === 401 || loginState.statusCode === 422) &&
              loginState.message !== 'email not found' &&
              loginState.message === 'Invalid email or password.') ||
              loginState.message ===
                'The request body is invalid. See error object `details` property for more info.'
          "
        >
          เข้าสู่ระบบไม่สําเร็จ อีเมล หรือ รหัสผ่าน ไม่ถูกต้อง
        </p>
      </div>

      <v-container cols="12" md="6" class="pb-0 pt-0 text-center">
        <v-btn
          width="100%"
          rounded
          color="primary"
          type="submit"
          light
          elevation="0"
          @click="validate"
          :disabled="!valid"
          class="loginform-btn-login"
          ><span class="loginform-btn-login-text">เข้าสู่ระบบ</span>
        </v-btn>
      </v-container>

      <v-row align="center" class="loginform-continue-title">
        <v-divider></v-divider>
        <p class="mb-0 ml-3 mr-3 text--regular--14">หรือดำเนินการต่อด้วย</p>
        <v-divider></v-divider>
      </v-row>

      <v-container class="login-btn-social-padding">
        <v-row>
          <v-col
            cols="6"
            style="
    padding-right: 16px;
"
          >
            <v-btn
              block
              class="loginform-btn-style"
              @click="logInWithFacebook"
              outlined
            >
              <img
                src="../assets/fb-logo.png"
                width="23"
                height="23"
                class="loginform-btn-icon-padding"
              />
              <span class="loginform-btn-social-text">Facebook</span>
            </v-btn>
            <div id="status"></div>
          </v-col>
          <v-col cols="6" style="padding-left: 0px;">
            <a :href="googleLink" style="text-decoration: none;">
              <v-btn block class="loginform-btn-style" outlined>
                <img
                  src="../assets/gmail-logo.svg"
                  width="22"
                  height="22"
                  class="loginform-btn-icon-padding"
                />
                <span class="loginform-btn-social-text">Google</span>
              </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _isEmpty from "lodash/isEmpty";

export default {
  computed: {
    ...mapState({
      loginState: state => {
        return state.authentication.status;
      },
      isShowErrorMessage: state => {
        return state.authentication.isShowErrorMessage;
      },
      googleLink: state => {
        return state.authentication.googleLink;
      }
    })
  },
  async created() {
    if (this.$route.params.param) {
      let decodeParam = decodeURIComponent(this.$route.params.param);
      let decodeBase64Param = atob(decodeParam);
      let json = JSON.parse(decodeBase64Param);
      let objParam = {
        ...json
      };
      if (objParam.status === "activation") {
        this.sendEMailActivateProfile(objParam);
      }
      if (objParam.status === "activated") {
        this.activateProfile(objParam);
      }
    }
    if (_isEmpty(this.$route.query)) {
      this.getGoogleUrlLogin();
    } else {
      if (this.$route.query) {
        let code = this.$route.query.code;
        let loginObj = {
          email: localStorage.getItem("mail"),
          code: code
        };
        let loginResponse = await this.loginGoogle(loginObj);
        if (loginResponse.error) {
          if (/.+@.+\..+/.test(loginResponse.error.message)) {
            localStorage.setItem("mail", loginResponse.error.message);
            this.setEmail(loginResponse.error.message);
            this.$emit("clicked", "register");
          }
        } else {
          localStorage.setItem("logintype", "google");
        }
      }
    }
    if (this.$route.name === "register") {
      this.$emit("clicked", "register");
    }
  },
  data: () => ({
    passwordInputType: "password",
    form: {
      email: "",
      password: ""
    },
    valid: true,
    visiblePassword: false, // Sasiprapa B. : 2021-05-08 : visible icon password
    passwordRules: [v => !!v || "กรุณาระบุ รหัสผ่าน"],
    emailRules: [
      v => !!v || "กรุณาระบุ อีเมล",
      v => /.+@.+\..+/.test(v) || "กรุณาระบุ รหัสผ่านให้ถูกต้อง"
    ]
  }),
  methods: {
    ...mapActions("authentication", [
      "login",
      "setShowErrorMessage",
      "getGoogleUrlLogin",
      "loginGoogle",
      "setEmail"
    ]),
    ...mapActions("user", ["updateProfile", "activateProfile"]),
    ...mapActions("sendmail", ["sendEMailActivateProfile"]),
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.setShowErrorMessage(false);
        this.login({
          ...this.form
        });
      }
    },
    toRegisterForm() {
      this.setShowErrorMessage(false);
      this.$emit("clicked", "register");
    },
    async logInWithFacebook() {
      let elementMethod = this;
      if (window.FB) {
        window.FB.login(
          response => {
            if (response.status === "connected") {
              window.FB.api(
                "/me",
                "GET",
                {
                  fields: "id,name,email"
                },
                async response => {
                  let userInfo = {
                    loginType: "facebook",
                    fullName: response.name,
                    email: response.email,
                    password: response.id
                  };
                  elementMethod.setShowErrorMessage(false);
                  let loginResponse = await elementMethod.login(userInfo);
                  if (loginResponse.error) {
                    if (/.+@.+\..+/.test(loginResponse.error.message)) {
                      this.setEmail(loginResponse.error.message);
                      this.$emit("clicked", "register");
                    }
                  } else {
                    localStorage.setItem("logintype", "facebook");
                  }
                }
              );
            }
          },
          {
            scope: "email"
          }
        );
      }
    },
    async logInWithGoogle() {
      let elementMethod = this;
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          let userInfo = {
            loginType: "google",
            google: GoogleUser,
            email: GoogleUser.gt.Rt,
            password: GoogleUser.Aa
          };
          elementMethod.setShowErrorMessage(false);
          elementMethod.login(userInfo);
        })
        .catch(e => e);
    },
    onClickForgotPassword() {
      this.$router.push(`/forgotpassword`);
    },
    onSwapInputType() {
      if (this.passwordInputType === "text") {
        this.passwordInputType = "password";
      } else {
        this.passwordInputType = "text";
      }
    }
  }
};
</script>

<style scoped>
.loginform--title {
  text-decoration: underline;
  cursor: pointer;
}

.loginform-booking {
  text-align: left;
}

.loginform-password {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  letter-spacing: 0px;
  /* margin-top: 23px; */
}

/* button */
.loginform-btn-login {
  max-width: 207px !important;
  height: 45px !important;
  border-radius: 23px;
  padding: 12px 75px;
  margin-top: 28.5px;
}

.loginform-btn-login-text {
  width: 57px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
}

.loginform-btn-social-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a0a0a;
}

.loginform-btn-style {
  max-width: 192px !important;
  height: 45px !important;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #ffffff;
  text-transform: capitalize;
  color: #0a0a0a;
}

.loginform-btn-icon-padding {
  margin-right: 12.4px;
}

.loginform-continue-title {
  margin: 42px 0px 0px 0px;
}

.loginform-gmail-text-field {
  border-radius: 5px;
  background-color: #ffffff;
  border-color: #afafaf;
  border-width: 1px;
  padding-top: 6px;
  min-height: 45px;
}

/* padding */
.loginform-reserve-space-padding {
  padding-top: 23px;
}

.loginform-gmail-padding {
  padding-top: 18px;
}

.login-btn-social-padding {
  padding: 18px 0px 61px 0px;
}

.loginfrom-password-mode-mobile {
  display: flex;
  justify-content: space-between;
}

/* mobile */
@media (max-width: 600px) {
  .login-btn-social-padding {
    padding: 29px 0px 0px 0px;
  }
  .loginform-btn-login {
    margin-top: 12px;
  }
}
@media (max-width: 959px) {
  .login-btn-social-padding {
    padding: 29px 0px 0px 0px;
  }
}

.loginform-forgot-password {
  /* Layout Properties */
  width: 66px;
  height: 21px;
  /* ui properties */
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  color: #282828;
  font: normal normal bold 14px/21px Kanit;
  letter-spacing: 0px;
  opacity: 1;
  text-align: right;
  /* margin-top: 12px; */
  cursor: pointer;
}

/* Desktop */
@media (max-width: 959px) {
}
</style>
