import axios from "axios";
import _get from "lodash/get";

export default {
  async getBookingHistory(filters) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/bookings?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/bookings`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async createBooking(data) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/bookings`, data);
      return response;
    } catch (error) {
      return null;
    }
  },
  async createBookingItems(data) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/booking-items-list`, data);
      return response;
    } catch (error) {
      return null;
    }
  },
  async getBookingItemHistory(filters) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/booking-items?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/booking-items`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async updateBookingStatus(param) {
    try {
      let status = _get(param, "status", "");
      const baseUrl = process.env.VUE_APP_URL;
      let url = `${baseUrl}/bookings`;
      const response = await axios.patch(`${url}/${param.bookingId}`, {
        status: status
      });
      return response;
    } catch (error) {
      return null;
    }
  }
};
