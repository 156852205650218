import authenticationApi from "../../api/authentication";
import { router } from "../../router";

const user = JSON.parse(localStorage.getItem("usertoken"));

const state = {
  loggedIn: user ? true : false,
  status: null,
  user,
  isShowErrorMessage: false,
  googleLink: null,
  registerEmail: null
};

const getters = {
  parseJwt() {
    let token = JSON.parse(localStorage.getItem("usertoken"));
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
};

const actions = {
  async login({ commit, dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await authenticationApi.login(param);
    commit("loginResponse", response);
    dispatch("user/getFullName", null, { root: true });
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  },
  logout({ commit }) {
    authenticationApi.logout();
    commit("logout");
  },
  setShowErrorMessage({ commit }, status) {
    commit("setShowErrorMessage", status);
  },
  async getGoogleUrlLogin({ commit }) {
    let response = await authenticationApi.getGoogleUrlLogin();
    commit("setGoogleLink", response);
  },
  async loginGoogle({ commit }, param) {
    let response = await authenticationApi.loginGoogle(param);
    commit("loginResponse", response);
    return response;
  },
  setEmail({ commit }, email) {
    commit("setEmail", email);
  }
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
    state.loggedIn = false;
  },
  logout(state) {
    state.status = {};
    state.user = null;
    state.loggedIn = false;
    router.push("/login");
  },
  loginResponse(state, response) {
    if (response.status === 200) {
      state.loggedIn = true;
      if (localStorage.getItem("keeplink")) {
        let path = localStorage.getItem("keeplink");
        if (path.indexOf("history" !== -1)) {
          router.push(path);
          localStorage.removeItem("keeplink");
        } else {
          localStorage.removeItem("keeplink");
        }
      } else {
        router.push("/rentspacecriteria");
      }
    } else {
      state.loggedIn = false;
      state.status = { ...response.error };
      state.isShowErrorMessage = true;
    }
  },
  setShowErrorMessage(state, param) {
    state.isShowErrorMessage = param;
  },
  setGoogleLink(state, param) {
    if (param) {
      if (param.data) {
        state.googleLink = param.data;
      }
    }
  },
  setEmail(state, email) {
    if (/.+@.+\..+/.test(email)) {
      state.registerEmail = email;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
