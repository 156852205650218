import areaAPI from "../../api/area";
import areacartAPI from "../../api/areacart";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _map from "lodash/map";
import _find from "lodash/find";
// import _maxby from "lodash/maxBy";
import _keyBy from "lodash/keyBy";
// import _flattenDeep from "lodash/flattenDeep";
// import _uniqBy from "lodash/uniqBy";
import _reduce from "lodash/reduce";
// import moment from "moment";
import _groupBy from "lodash/groupBy";

const state = () => ({
  areaLists: null,
  areaCart: [],
  areaCartConfirmation: [], //Start : Sasiprapa B. : 2021-04-23
  isOneDaySelected: false
});

const getters = {
  areaCartSummaryPriceCalculate: (state, getters) => {
    let summary = 0;
    let objecTivePrice = 0;
    if (state.areaCart.length > 0) {
      summary += getters.areaCartDepositCalculate;

      for (const areaItem of state.areaCart) {
        const getStartDate = areaItem.startDate;
        const getEndDate = areaItem.endDate;
        const date1 = new Date(getStartDate);
        const date2 = new Date(getEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        if (diffDays <= 7) {
          summary += areaItem.maintenanceCost7Days || 0;
        }

        if (diffDays > 7 && diffDays < 30) {
          summary += areaItem.maintenanceCost7Days || 0;
          summary += (diffDays - 7) * areaItem.maintenanceCostOdd || 0;
        }

        if (diffDays === 30) {
          summary += areaItem.maintenanceCost30Days || 0;
        }

        if (diffDays > 30) {
          summary += areaItem.maintenanceCost30Days || 0;
          summary += (diffDays - 30) * areaItem.maintenanceCostOdd || 0;
        }
      }
    }
    return summary + objecTivePrice;
  },
  areaCartMaintenanceCostCalculate: state => {
    // let getApplicantType = _get(globalState.rentspacecriteria, "applicantType", "");
    let summary = 0;

    if (state.areaCart.length > 0) {
      for (const areaItem of state.areaCart) {
        const getStartDate = areaItem.startDate;
        const getEndDate = areaItem.endDate;
        const date1 = new Date(getStartDate);
        const date2 = new Date(getEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        if (diffDays <= 7) {
          summary += areaItem.maintenanceCost7Days || 0;
        }

        if (diffDays > 7 && diffDays < 30) {
          summary += areaItem.maintenanceCost7Days || 0;
          // if (getApplicantType !== "GOVERNMENTAGENCY") {
          summary += (diffDays - 7) * areaItem.maintenanceCostOdd || 0;
          // }
        }

        if (diffDays === 30) {
          summary += areaItem.maintenanceCost30Days || 0;
        }

        if (diffDays > 30) {
          summary += areaItem.maintenanceCost30Days || 0;
          // if (getApplicantType !== "GOVERNMENTAGENCY") {
          summary += (diffDays - 30) * areaItem.maintenanceCostOdd || 0;
          // }
        }
      }
    }
    return summary;
  },
  areaCartDepositCalculate: (state, getters, globalState) => {
    let summary = 0;
    let getApplicantType = _get(
      globalState.rentspacecriteria,
      "applicantType",
      ""
    );
    if (state.areaCart.length > 0) {
      const groupByObj = _groupBy(state.areaCart, e => {
        const getObj = _get(e, "objective[0]", "");
        return getObj;
      });
      for (const objKey in groupByObj) {
        if (Object.hasOwnProperty.call(groupByObj, objKey) && objKey !== "") {
          const element = groupByObj[objKey];
          const reduceDateCount = _reduce(
            element,
            (sum, n) => {
              if (n.objective.length > 0) {
                const getStartDate = _get(n, "startDate", "");
                const getEndDate = _get(n, "endDate", "");
                const getDepositStartDate = getStartDate;
                const getDepositEndDate = getEndDate;
                const dateDepo1 = new Date(getDepositStartDate);
                const dateDepo2 = new Date(getDepositEndDate);
                const depoDiffTime = Math.abs(dateDepo2 - dateDepo1);
                const depoDiffDays =
                  Math.ceil(depoDiffTime / (1000 * 60 * 60 * 24)) + 1;
                return Number(sum) + Number(depoDiffDays);
              } else {
                return Number(sum);
              }
            },
            0
          );

          let deposit = Math.ceil(reduceDateCount / 7) * 10000;
          if (getApplicantType !== "GOVERNMENTAGENCY") {
            summary += deposit;
          }
        }
      }
    }
    return summary;
  },
  getUnAvailableArea: (state, getters, globalState) => {
    let filterSuccessStatus = [];
    if (state.areaLists !== null) {
      filterSuccessStatus = _filter(
        globalState.booking.bookingItemHistoryTemp,
        o => {
          let getBookingStatus = _get(o, "booking.status", "");
          return (
            getBookingStatus === "สำเร็จ" ||
            getBookingStatus === "อนุญาต คำขอใช้พื้นที่" ||
            getBookingStatus === "รอการชำระเงิน" ||
            getBookingStatus === "ชำระเงินเรียบร้อย"
          );
        }
      );
    }
    return filterSuccessStatus;
  },
  getAvailableAreaForDate: (state, getters, globalState) => {
    if (state.areaLists !== null) {
      let endDate = globalState.datespecify.endDate;
      let startDate = globalState.datespecify.startDate;
      let requestType = globalState.rentspacecriteria.requestType;
      if (requestType === "DATE" && endDate && startDate) {
        let filterSuccessStatus = _filter(
          globalState.booking.bookingItemHistoryTemp,
          o => {
            let getBookingStatus = _get(o, "booking.status", "");
            return (
              getBookingStatus === "สำเร็จ" ||
              getBookingStatus === "อนุญาต คำขอใช้พื้นที่" ||
              getBookingStatus === "รอการชำระเงิน" ||
              getBookingStatus === "ชำระเงินเรียบร้อย"
            );
          }
        );

        let isStoreDupRange = [];
        if (filterSuccessStatus.length > 0) {
          for (const iterator of filterSuccessStatus) {
            let startdate = new Date(iterator.startDate).setHours(0, 0, 0, 0);
            let enddate = new Date(iterator.endDate).setHours(0, 0, 0, 0);
            let startD = new Date(startDate).setHours(0, 0, 0, 0);
            let endD = new Date(endDate).setHours(0, 0, 0, 0);

            let isInRange =
              (startD >= startdate && startD <= enddate) ||
              (startdate >= startD && startdate <= endD) ||
              (startdate == startD && enddate == endD);

            if (isInRange) {
              isStoreDupRange.push(iterator);
            }
          }
          if (isStoreDupRange.length === 0) {
            return state.areaLists;
          } else {
            let lookup = _keyBy(isStoreDupRange, o => {
              return o.roomId;
            });
            let filterAvailableArea = _filter(state.areaLists, u => {
              return lookup[u.id] === undefined;
            });
            return filterAvailableArea;
          }
        } else {
          return state.areaLists;
        }
      } else {
        return state.areaLists;
      }
    }
    return null;
  },
  getAvailableAreaForArea: (state, getters, globalState) => selectAreaItem => {
    if (state.areaLists !== null) {
      let endDate = selectAreaItem.endDate;
      let startDate = selectAreaItem.startDate;

      let filterSuccessStatus = _filter(
        globalState.booking.bookingItemHistoryTemp,
        o => {
          let getBookingStatus = _get(o, "booking.status", "");
          return (
            getBookingStatus === "สำเร็จ" ||
            getBookingStatus === "อนุญาต คำขอใช้พื้นที่" ||
            getBookingStatus === "รอการชำระเงิน" ||
            getBookingStatus === "ชำระเงินเรียบร้อย"
          );
        }
      );

      let isStoreDupRange = [];
      if (filterSuccessStatus.length > 0) {
        for (const iterator of filterSuccessStatus) {
          let startdate = new Date(iterator.startDate).setHours(0, 0, 0, 0);
          let enddate = new Date(iterator.endDate).setHours(0, 0, 0, 0);
          let startD = new Date(startDate).setHours(0, 0, 0, 0);
          let endD = new Date(endDate).setHours(0, 0, 0, 0);

          let isInRange =
            (startD >= startdate && startD <= enddate) ||
            (startdate >= startD && startdate <= endD) ||
            (startdate == startD && enddate == endD);

          if (isInRange) {
            isStoreDupRange.push(iterator);
          }
        }
        if (isStoreDupRange.length === 0) {
          return state.areaLists;
        } else {
          let lookup = _filter(isStoreDupRange, o => {
            return o.roomId === selectAreaItem.roomID;
          });
          if (lookup.length > 0) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    return null;
  },
  areaGetPricePerRoom: state => selectAreaItem => {
    let summary = 0;
    if (state.areaCart.length > 0) {
      let areaItem = selectAreaItem;
      const getStartDate = areaItem.startDate;
      const getEndDate = areaItem.endDate;
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      if (diffDays <= 7) {
        summary += areaItem.maintenanceCost7Days || 0;
      }

      if (diffDays > 7 && diffDays < 30) {
        summary += areaItem.maintenanceCost7Days || 0;
        summary += (diffDays - 7) * areaItem.maintenanceCostOdd || 0;
      }

      if (diffDays === 30) {
        summary += areaItem.maintenanceCost30Days || 0;
      }

      if (diffDays > 30) {
        summary += areaItem.maintenanceCost30Days || 0;
        summary += (diffDays - 30) * areaItem.maintenanceCostOdd || 0;
      }
    }
    return summary;
  },
  getIsAvailableAreaForDate: (state, getters, globalState) => {
    if (state.areaLists !== null && state.areaCart.length > 0) {
      let filterSuccessStatus = _filter(
        globalState.booking.bookingItemHistoryTemp,
        o => {
          let getBookingStatus = _get(o, "booking.status", "");
          return getBookingStatus === "สำเร็จ";
        }
      );

      for (const item of state.areaCart) {
        let endDate = item.endDate;
        let startDate = item.startDate;

        let isStoreDupRange = [];
        if (filterSuccessStatus.length > 0) {
          for (const iterator of filterSuccessStatus) {
            let startdate = new Date(iterator.startDate).setHours(0, 0, 0, 0);
            let enddate = new Date(iterator.endDate).setHours(0, 0, 0, 0);
            let startD = new Date(startDate).setHours(0, 0, 0, 0);
            let endD = new Date(endDate).setHours(0, 0, 0, 0);

            let isInRange =
              (startD >= startdate && startD <= enddate) ||
              (startdate >= startD && startdate <= endD) ||
              (startdate == startD && enddate == endD);

            if (isInRange) {
              isStoreDupRange.push(iterator);
            }
          }
          if (isStoreDupRange.length === 0) {
            return false;
          } else {
            let lookup = _keyBy(isStoreDupRange, o => {
              return o.roomId;
            });
            let filterAvailableArea = _filter(state.areaCart, u => {
              return lookup[u.id] !== undefined;
            });

            if (filterAvailableArea.length > 0) {
              let getRoomName = _get(filterAvailableArea, "[0].name", "");
              return getRoomName;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    }
    return false;
  }
};

const actions = {
  resetStateData({ commit }) {
    commit("resetStateData");
  },
  async getAreaLists({ commit, dispatch }, filters) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await areaAPI.getAvailableArea(filters);
    commit("setAreaLists", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  getAreaCart({ commit, dispatch }) {
    areacartAPI.getAreaCart(areaCart => {
      dispatch("app/setOverlayState", true, { root: true });
      commit("setAreaCart", areaCart);
      dispatch("app/setOverlayState", false, { root: true });
    });
  },
  setAreaSelected({ commit }, param) {
    commit("setAreaSelected", param);
  },
  setObjective({ commit }, param) {
    commit("setObjective", param);
  },
  setErrorAreaCart({ commit }, index) {
    commit("setErrorAreaCart", index);
  },
  setOtherObjective({ commit }, param) {
    commit("setOtherObjective", param);
  },
  // Start : Sasiprapa B. :2021-04-23
  setAreaConfirmationSelected({ commit }, param) {
    commit("setAreaConfirmationSelected", param);
  },
  setAreaCardSelected({ commit }, param) {
    commit("setAreaCardSelected", param);
  }
  // End : Sasiprapa B. :2021-04-23
};

const mutations = {
  resetStateData(state) {
    Object.assign(state, {
      areaLists: null,
      areaCart: [],
      isOneDaySelected: false
    });
  },
  setAreaLists(state, payload) {
    if (payload.status === 200) {
      payload.data = payload.data.map(o => {
        return { ...o, isSelected: false };
      });
      state.areaLists = payload.data;
    }
  },
  setAreaCart(state, areaCart) {
    state.areaCart = areaCart;
  },
  setAreaSelected(state, { item, startDate, endDate, isRemove, editArea }) {
    // Start :Sasiprapa B. : 2021-05-06 : add mode are if click edit button
    //edit Are mode
    if (editArea) {
      // let areCart = state.areaCart;
      // delete old
      let findItemAreaCartSelected = _find(state.areaCart, o => {
        return o.id === item.id;
      });
      if (findItemAreaCartSelected) {
        state.areaCart = state.areaCart.filter(result => result.id !== item.id);
      }
      // create new
      let findItemAreaListsSelected = _find(state.areaLists, o => {
        return o.id === item.id;
      });
      if (findItemAreaListsSelected.isSelected) {
        state.areaCart.push({
          ...findItemAreaListsSelected,
          startDate,
          endDate,
          objective: [],
          otherObjective: "",
          isError: false
        });
      }
      return;
    }
    // End :Sasiprapa B. : 2021-05-06 : add mode are if click edit button
    if (!isRemove) {
      state.areaLists = _map(state.areaLists, o => {
        if (item.id === o.id) {
          o.isSelected = !o.isSelected;
        }
        return {
          ...o
        };
      });
      let findItemSelected = _find(state.areaLists, o => {
        return o.id === item.id;
      });
      if (findItemSelected.isSelected) {
        state.areaCart.push({
          ...findItemSelected,
          startDate,
          endDate,
          objective: [],
          otherObjective: "",
          isError: false
        });
      }
    } else {
      state.areaLists = _map(state.areaLists, o => {
        if (item.id === o.id) {
          o.isSelected = false;
        }
        return {
          ...o
        };
      });
      let keyUniq = `${item.id}${item.startDate}${item.endDate}`;
      state.areaCart = _filter(state.areaCart, o => {
        return `${o.id}${o.startDate}${o.endDate}` !== keyUniq;
      });
    }
  },
  setObjective(state, { event, value, index }) {
    if (value) {
      if (event.length > 1) {
        state.areaCart[index].objective = [event[1]];
      }
      // state.areaCart[index].objective = event;
      if (state.areaCart[index].objective.indexOf("อื่นๆ") === -1) {
        state.areaCart[index].otherObjective = "";
        state.areaCart[index].isError = false;
        if (event.length === 0) {
          state.areaCart[index].isError = true;
        }
      } else {
        if (state.areaCart[index].otherObjective === "") {
          state.areaCart[index].isError = true;
        } else {
          state.areaCart[index].isError = false;
        }
      }
    } else {
      let filterEvent = _filter(
        state.areaCart[index].objective,
        o => o !== value
      );
      state.areaCart[index].objective = filterEvent;
      if (value === "อื่นๆ") {
        state.areaCart[index].otherObjective = "";
      } else {
        state.areaCart[index].objective = state.areaCart[
          index
        ].objective.filter(o => o !== value);
      }
    }
  },
  setErrorAreaCart(state, index) {
    state.areaCart[index].isError = true;
  },
  setOtherObjective(state, { index, text }) {
    state.areaCart[index].otherObjective = text;
    if (
      state.areaCart[index].objective.indexOf("อื่นๆ") !== -1 &&
      state.areaCart[index].otherObjective
    ) {
      state.areaCart[index].isError = false;
    } else if (
      state.areaCart[index].objective.indexOf("อื่นๆ") !== -1 &&
      !state.areaCart[index].otherObjective
    ) {
      state.areaCart[index].isError = true;
    }
  },
  // Start :  Sasiprapa B. : 2021-04-23
  setAreaConfirmationSelected(state, item) {
    state.areaCartConfirmation = item;
  },
  setAreaCardSelected(state, item) {
    state.areaCart = item;
  }
  // End :  Sasiprapa B. : 2021-04-23
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
