import sendMail from "../../api/sendmail";

// initial state
const state = () => ({
  all: []
});

// getters
const getters = {};

// actions
const actions = {
  async sendEMail({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await sendMail.sendMail(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  },
  async sendEMailWelCome({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await sendMail.sendMailWelcome(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  },
  async sendEMailActivateProfile({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    await sendMail.sendEMailActivateProfile(param);
    localStorage.removeItem("keeplink");
    dispatch("app/setOverlayState", false, { root: true });
  },
  async sendEMailUserCancel({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await sendMail.sendEMailUserCancel(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
