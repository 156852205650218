<template>
  <div class="regulations-info">
    <v-container class="py-8 px-6 hidden-sm-and-down">
      <v-col>
        <p class="text--bold--18">กฏระเบียบในการจองพื้นที่</p>
        <div v-if="documentLists !== null">
          <div v-for="item in documentLists" :key="item.id">
            <v-container>
              <v-row>
                <v-col cols="6" sm="6" md="9" lg="10" xl="10">
                  <span class="text--regular--14">{{ item.title }}</span>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  class="text--regular--14 pl-0"
                >
                  <a
                    v-bind:href="getDownloadFileURL(item.filename)"
                    style="text-decoration: none;"
                    class="d-flex align-baseline"
                  >
                    <img
                      class="pt-2"
                      width="15"
                      height="15"
                      src="../assets/icons/home-download.svg"
                      alt=""
                    />
                    <span
                      style="color:#282828; text-decoration: none;"
                      class="pl-2"
                      >ดาวน์โหลด</span
                    >
                  </a>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
          </div>
        </div>
        <div v-if="imagesLists !== null">
          <v-container
            class="pa-0 mt-10"
            v-for="item in imagesLists"
            :key="item.id"
          >
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              class="regulations-image"
              :src="getDownloadFileURL(item.filename)"
            ></v-img>
          </v-container>
        </div>
      </v-col>
    </v-container>
    <!-- <v-container class="py-8 px-6 hidden-sm-and-down">
      <v-col>
        <p class="text--bold--18">กฏระเบียบในการจองพื้นที่</p>
        <div v-if="documentLists !== null">
          <div v-for="item in documentLists" :key="item.id">
            <table class="regulation--table">
              <tr>
                <td>
                  <span class="text--regular--14">{{ item.title }}</span>
                </td>
                <td class="regulation--text--alignRight">
                  <span class="text--regular--14">
                    <a v-bind:href="getDownloadFileURL(item.filename)" style="text-decoration: none;">
                      <img class="pt-2" width="15" height="15" src="../assets/icons/home-download.svg" alt="" />
                      <span style="color:#282828; text-decoration: none;" class="pl-2">ดาวน์โหลด</span>
                    </a>
                  </span>
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
          </div>
        </div>
        <div v-if="imagesLists !== null">
          <v-container class="pa-0 mt-10" v-for="item in imagesLists" :key="item.id">
            <v-img lazy-src="https://picsum.photos/id/11/10/6" max-height="auto" max-width="648"
              :src="getDownloadFileURL(item.filename)"></v-img>
          </v-container>
        </div>
      </v-col>
    </v-container> -->
    <v-container class="hidden-md-and-up pa-0">
      <v-list class="pt-0 pb-0">
        <v-list-group :value="false" color="black" class="bg-active">
          <template v-slot:activator>
            <v-list-item-title class="text--bold--16"
              >กฏระเบียบในการจองพื้นที่</v-list-item-title
            >
          </template>
          <v-list-item-content class="bg-active-content">
            <div v-if="documentLists !== null">
              <div v-for="item in documentLists" :key="item.id">
                <v-container class="regulation--table">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <span class="text--regular--14">{{ item.title }}</span>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="text--regular--14">
                      <a
                        v-bind:href="getDownloadFileURL(item.filename)"
                        style="text-decoration: none;"
                        class="d-flex justify-space-between align-baseline"
                      >
                        <img
                          class="pt-2"
                          width="15"
                          height="15"
                          src="../assets/icons/home-download.svg"
                          alt=""
                        />
                        <span
                          style="color:#282828; text-decoration: none;"
                          class="pl-2"
                          >ดาวน์โหลด</span
                        >
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>
              </div>
            </div>
            <div v-if="imagesLists !== null">
              <v-container
                class="pa-0 mt-10"
                v-for="item in imagesLists"
                :key="item.id"
              >
                <v-img
                  lazy-src="https://picsum.photos/id/11/10/6"
                  class="regulations-image"
                  :src="getDownloadFileURL(item.filename)"
                ></v-img>
              </v-container>
            </div>
          </v-list-item-content>
        </v-list-group>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    baseURL: process.env.VUE_APP_URL,
    image1: require("../assets/home-Info-01@2x.png"),
    cards: ["Today", "Yesterday"],
    drawer: null,
    links: [
      ["mdi-inbox-arrow-down", "Inbox"],
      ["mdi-send", "Send"],
      ["mdi-delete", "Trash"],
      ["mdi-alert-octagon", "Spam"]
    ]
  }),
  created() {
    const filters = {
      order: "seq asc"
    };
    this.initPage(filters);
  },
  computed: {
    ...mapGetters("regulations", {
      documentLists: "getDocType",
      imagesLists: "getImageType"
    })
  },
  methods: {
    ...mapActions("regulations", ["initPage"]),
    getDownloadFileURL(filename) {
      if (filename) {
        return `${this.baseURL}/files/${encodeURIComponent(filename)}`;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.regulation--text--alignRight {
  float: right;
  cursor: pointer;
}

.regulation--table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.regulations-info {
  overflow-y: scroll;
  height: 100vh;
}

.regulations-group-active {
  border-radius: 4px;
}

.bg-active {
  background-color: #f5d257 !important;
  border-radius: 4px;
}

.bg-active-content {
  background-color: white !important;
}

.regulations-image {
  max-height: auto;
  max-width: 648px;
}
</style>
