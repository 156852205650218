<template>
  <v-footer padless>
    <v-card
      flat
      tile
      class="footer--background white--text text-center footer-height"
      width="100%"
    >
      <v-card-text class="white--text pt-0 pb-0">
        <v-container>
          <v-row class="mt-5">
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              lg="4"
              class="pt-0 footer--text"
            >
              <p class=" mb-0 text--bold--16">Address</p>
              <p class=" mb-0 text--regular--12 footer-address-padding">
                84 Ratchadamnoen Klang Avenue, Bawonniwet <br />
                Sub-District, Phra Nakhon District,<br />
                Bangkok 10200
              </p>
              <p class="mb-0 text--regular--12 footer-gmail-padding ">
                rcac.talk@gmail.com
              </p>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="3"
              md="4"
              lg="5"
              class="footer--text footer-call-padding"
            >
              <p class="mb-0 text--bold--16 footer-call-us-header-padding">
                Call Us
              </p>
              <p class="mb-0 text--regular--12 footer-call-us-padding">
                Phone. 0 2224 8030 ext. 202 <br />
                Fax. 0 2224 8031
              </p>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              lg="3"
              class="footer-social footer-social-padding"
            >
              <v-btn
                class="pt-0 footer-social-icon-margin white--text footer--background-icon"
                elevation="2"
                fab
                icon
                width="41"
                height="41"
                @click="changeRoute('https://www.facebook.com/rcac84/')"
              >
                <img src="../assets/facebook-app-logo.svg" size="24px" />
              </v-btn>
              <v-btn
                class="footer-social-icon-margin white--text footer--background-icon"
                elevation="2"
                fab
                icon
                width="41"
                height="41"
                @click="changeRoute('https://www.instagram.com/84rcac/')"
              >
                <img src="../assets/instagram-logo.svg" size="24px" />
              </v-btn>
              <v-btn
                class="footer-social-icon-margin white--text footer--background-icon"
                elevation="2"
                fab
                icon
                width="41"
                height="41"
                @click="
                  changeRoute(
                    'https://www.youtube.com/channel/UCc7ys6t5dGn6pA2bdx2JK8w/featured'
                  )
                "
              >
                <img src="../assets/youtube-logo.svg" size="24px" />
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="footer--text text--regular--12 pt-0 pb-0"
            >
              <div class="footer-copyright">
                <p class="mb-0" style="height: 18px;">
                  Copyright © Ratchadamnoen Contemporary Art Center.
                </p>
                <p class="mb-0" style="height: 18px;">All rights reserved.</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    // icons: ["mdi-facebook", "mdi-instagram", "mdi-youtube", "mdi-twitter"]
    // icons: ["../assets/facebook-app-logo.svg", "../assets/instagram-logo.svg", "../assets/youtube-logo.svg",
    //   "../assets/twitter-social-logo.svg"
    // ]
  }),
  methods: {
    changeRoute(router) {
      window.open(router, "_blank");
    }
  }
};
</script>
<style scoped>
.footer--text {
  text-align: start;
}

.footer-social {
  text-align: end;
}

.footer-copyright {
  display: flex;
  flex-direction: row;
  padding-top: 27px;
  padding-bottom: 28px;
}

.footer--background {
  background-color: #363636;
}

.footer--background-icon {
  background-color: rgb(255, 255, 255, 0.2);
  /* margin: 0 10px 10px 0; */
}

.footer-margin-bottom-info {
  margin-bottom: 1px;
}
/* Padding Section */
.footer-address-padding {
  padding-top: 4.5px;
  line-height: 18px;
}
.footer-gmail-padding {
  padding-top: 8px;
}
.footer-call-us-padding {
  padding-top: 4.5px;
  line-height: 18px;
}
.footer-social-icon-margin {
  margin-left: 16px;
  margin-top: 16px;
}
.footer-call-us-header-padding {
  padding-top: 0px;
}
.footer-social-padding {
  padding-top: 0px;
}
.footer-call-padding {
  padding-bottom: 12px;
  padding-top: 0px;
}
</style>
