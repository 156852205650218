import _get from "lodash/get";
import _reduce from "lodash/reduce";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import _filter from "lodash/filter";
import moment from "moment";

import bookingApi from "../../api/booking";

const state = () => ({
  bookingData: null,
  bookingItemData: null,
  bookingHistory: null,
  bookingDetailItemsSelcted: null,
  bookingItemHistory: [],
  bookingItemHistoryTemp: []
});

const getters = {
  getBookingByBookingNo: state => bookingNo => {
    return _filter(state.bookingHistory, e => e.bookingNo === bookingNo);
  },
  getDisplayBookingHistory: state => {
    let getBookingHistory = state.bookingHistory;
    if (getBookingHistory !== null) {
      let storeResponse = [];
      for (let [index, item] of getBookingHistory.entries()) {
        let getCreateDate = _get(item, "created_date", "");
        let date = new Date(getCreateDate);
        let createdDateTh = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });

        let obj = {
          id: (index += 1),
          applicantType: item.applicantType,
          thname: item.thname,
          department_name: item.department_name,
          group_name: item.group_name,
          tel: item.tel,
          activities_detail: item.activities_detail,
          setup_start_date: item.setup_start_date,
          setup_start_time: item.setup_start_time,
          setup_end_time: item.setup_end_time,
          remove_start_date: item.remove_start_date,
          remove_start_time: item.remove_start_time,
          remove_end_time: item.remove_end_time,
          event_start_time: item.event_start_time,
          event_end_time: item.event_end_time,
          equipment: item.equipment,
          parking: item.parking,
          bookingNo: item.bookingNo,
          activities: _get(item, "activities", ""),
          created_date: createdDateTh,
          status: _get(item, "status", ""),
          remark: _get(item, "remark", ""),
          detail: _get(item, "bookingItems", []),
          maintenanceCost: _get(item, "maintenanceCost", 0).toLocaleString(),
          depositPrice: _get(item, "depositPrice", 0).toLocaleString(),
          summary: `${_get(item, "summary", 0).toLocaleString()} บาท`
        };
        storeResponse.push(obj);
      }
      return storeResponse;
    }
    return null;
  },
  getDisplayEvent: state => isMobile => {
    let getBookingItemHistory = state.bookingItemHistory;
    if (getBookingItemHistory.length > 0) {
      let groupByDate = _groupBy(getBookingItemHistory, o => {
        return `${o.startDate}${o.endDate}`;
      });
      let reduceUser = _map(groupByDate, val => {
        let sumUser = _reduce(
          val,
          (sum, n) => {
            let getBookingStatus = _get(n, "booking.status", "");

            if (
              getBookingStatus === "อยู่ระหว่างการตรวจสอบ" ||
              getBookingStatus === "รอการแก้ไขพื้นที่" ||
              getBookingStatus === "ปรับเปลี่ยนพื้นที่"
            ) {
              if (sum.userId !== n.userId) {
                sum.sum += 1;
                sum.userId = n.userId;
                sum.startDate = n.startDate;
                sum.endDate = n.endDate;
              }
            }
            return sum;
          },
          { sum: 0, userId: "", startDate: "", endDate: "" }
        );
        return sumUser;
      });
      let storeResponse = [];
      for (let item of reduceUser) {
        let getUserCount = _get(item, "sum", "");
        let addEndDate = moment(item.endDate, "")
          .add(1, "days")
          .format("YYYY-MM-DD");
        let obj = {
          title: isMobile ? "" : `ผู้ขอใช้ : ${getUserCount} คน`,
          peopleUse: `${getUserCount} คน`,
          start: item.startDate,
          end: addEndDate,
          color: "var(--v-primary)",
          textColor: "black",
          allDay: true
        };
        storeResponse.push(obj);
      }
      return storeResponse;
    }
    return [];
  },
  getUserBookingCount: (state, getters, globalState) => roomId => {
    let getBookingItemHistory;
    if (globalState.rentspacecriteria.requestType === "DATE") {
      getBookingItemHistory = state.bookingItemHistoryTemp;
    } else {
      let getRoomId = _map(globalState.areaspecify.areaCart, o => o.id);
      getBookingItemHistory = _filter(
        state.bookingItemHistoryTemp,
        o => getRoomId.indexOf(o.roomId) !== -1
      );
    }
    let getEndDate = globalState.datespecify.endDate;
    let getStartDate = globalState.datespecify.startDate;
    if (getBookingItemHistory.length > 0 && getEndDate && getStartDate) {
      let count = _reduce(
        getBookingItemHistory,
        (objSum, o) => {
          let dateFormat = "YYYY-MM-DD";

          let momentStartDate = moment(o.startDate, dateFormat);
          let momentEndDate = moment(o.endDate, dateFormat);

          // selected in ui
          let startDateSelected = moment(getStartDate)
            .startOf("day")
            .subtract(1, "ms");
          let endDateSelected = moment(getEndDate).endOf("day");

          let selectedCheckStartDate = momentEndDate.isBetween(
            startDateSelected,
            endDateSelected
          );
          let selectedCheckEndDate = momentStartDate.isBetween(
            startDateSelected,
            endDateSelected
          );

          // history
          let momentStartDateMain = momentStartDate
            .startOf("day")
            .subtract(1, "ms");
          let momentEndDateMain = momentEndDate.endOf("day");

          let historyEndDateIsBetween = moment(getStartDate)
            .startOf("day")
            .isBetween(momentStartDateMain, momentEndDateMain);
          let historyStartDateIsBetween = moment(getEndDate)
            .startOf("day")
            .isBetween(momentStartDateMain, momentEndDateMain);

          if (
            o.roomId === roomId &&
            (o.booking.status === "อยู่ระหว่างการตรวจสอบ" ||
              o.booking.status === "รอการแก้ไขพื้นที่" ||
              o.booking.status === "ปรับเปลี่ยนพื้นที่") &&
            (selectedCheckStartDate ||
              selectedCheckEndDate ||
              historyEndDateIsBetween ||
              historyStartDateIsBetween)
          ) {
            // if (objSum.userId !== o.userId) {
            objSum.sum += 1;
            objSum.userId = o.userId;
            return objSum;
            // } else {
            //   objSum.sum += 0;
            //   objSum.userId = "";
            //   return objSum;
            // }
          } else {
            objSum.sum += 0;
            objSum.userId = "";
            return objSum;
          }
        },
        { sum: 0, userId: "" }
      );
      return count.sum || 0;
    }
    return 0;
  }
};

const actions = {
  resetStateData({ commit }) {
    commit("resetStateData");
  },
  async getBookingHistory({ commit, dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingApi.getBookingHistory(param);
    commit("bookingHistoryResult", result);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async createBooking({ commit, dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingApi.createBooking(param);
    commit("createBookingResult", result);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async createBookingItems({ commit, dispatch }, param) {
    let getUserId = _get(param, "[0].userId", "");
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingApi.createBookingItems(param);
    commit("createBookingItemResult", result);
    const filters = {
      order: "id asc",
      where: {
        userId: getUserId
      },
      fields: {
        id: true,
        userId: true,
        zipcode: true,
        thname: true,
        enname: true,
        address: true,
        subdistrict: true,
        province: true,
        district: true,
        mobile: true,
        activities: true,
        activityImageList: true,
        isRequireAccessory: true,
        status: true,
        created_date: true,
        updated_date: true,
        maintenanceCost: true,
        summary: true,
        depositPrice: true,
        bookingNo: true
      },
      include: [
        {
          relation: "bookingItems",
          scope: {
            order: "id asc",
            include: [
              {
                relation: "room",
                scope: {
                  order: "id asc"
                }
              }
            ]
          }
        }
      ]
    };
    dispatch("getBookingHistory", filters);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  setBookingDetailItemsSelcted({ commit }, param) {
    commit("setBookingDetailItemsSelcted", param);
  },
  async getBookingItemHistory({ commit }, filter) {
    // dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingApi.getBookingItemHistory(filter);
    commit("getBookingItemHistory", result);
    // dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async getBookingItemHistoryTemp({ commit }, filter) {
    const result = await bookingApi.getBookingItemHistory(filter);
    commit("getBookingItemHistoryTemp", result);

    return result;
  },
  async updateBookingStatus({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingApi.updateBookingStatus(param);
    dispatch("app/setOverlayState", true, { root: true });
    return result;
  }
};

const mutations = {
  resetStateData(state) {
    Object.assign(state, {
      bookingData: null,
      bookingItemData: null,
      bookingHistory: null,
      bookingDetailItemsSelcted: null,
      bookingItemHistory: []
    });
  },
  createBookingResult(state, param) {
    if (_get(param, "status", null) === 200) {
      state.bookingData = param.data;
    }
  },
  createBookingItemResult(state, param) {
    if (_get(param, "status", null) === 200) {
      state.bookingItemData = param.data;
    }
  },
  bookingHistoryResult(state, param) {
    if (_get(param, "status", null) === 200) {
      state.bookingHistory = param.data;
    }
  },
  setBookingDetailItemsSelcted(state, param) {
    state.bookingDetailItemsSelcted = param;
  },
  getBookingItemHistory(state, param) {
    if (_get(param, "status", null) === 200) {
      state.bookingItemHistory = param.data;
    }
  },
  getBookingItemHistoryTemp(state, param) {
    if (_get(param, "status", null) === 200) {
      state.bookingItemHistoryTemp = param.data;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
