<template>
  <div>
    <div v-if="showFuckingMessage">
      <v-container class="text-center">
        <p class="mb-0 text-center text--bold--16">
          โปรดตรวจสอบอีเมลและยืนยันตัวตน ในอีเมลเดียว
        </p>
        <p class="mb-0 text-center text--bold--16">
          กับที่ท่านสมัครไว้ ก่อนทําการเข้าสู่ระบบ
        </p>

        <p
          class="mt-8 mb-0 text--regular--14 loginform-booking mb-0 registerform-Register-padding"
        >
          หากท่านยืนยันตัวตนเรียบร้อยแล้ว โปรดเข้าสู่ระบบ
        </p>

        <v-container
          cols="12"
          md="6"
          class="pb-0 text-center registerform-login-padding"
        >
          <v-btn
            width="100%"
            rounded
            color="primary"
            light
            elevation="0"
            @click="toLoginForm"
            class="registerform-btn"
          >
            <span class="registerform-btn-text">เข้าสู่ระบบ</span></v-btn
          >
        </v-container>
      </v-container>
    </div>
    <div v-if="!showFuckingMessage">
      <p class="mb-0 text-center text--regular--14">
        มีบัญชีในระบบแล้ว
        <span
          class="primary--text registerform--title text--regular--14"
          @click="toLoginForm"
          >เข้าสู่ระบบ</span
        >
      </p>
      <p
        class="text--bold--18 loginform-booking mb-0 registerform-Register-padding"
      >
        สมัครสมาชิก
      </p>
      <p class="text--regular--14 loginform-booking mb-0">
        โปรดอ่านกฏระเบียบในการจองพื้นที่เพื่อทราบถึงข้อมูลที่จำเป็น
      </p>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="registerform-detail-padding"
      >
        <p class="mb-0 text--bold--14">ชื่อ - นามสกุล</p>
        <v-text-field
          :rules="nameRules"
          v-model="form.fullName"
          label=""
          single-line
          outlined
          required
          dense
          class="registerform-detail-field"
        ></v-text-field>

        <p
          class="mb-0 text--bold--14 registerform-detail-contact-number-padding"
        >
          เบอร์โทรติดต่อ
        </p>
        <v-text-field
          :rules="phoneNumberRules"
          v-model="form.mobile"
          v-mask="'##########'"
          label=""
          single-line
          outlined
          required
          type="number"
          dense
          counter
          maxlength="10"
          class="registerform-detail-field"
        ></v-text-field>

        <p class="mb-0 text--bold--14 registerform-detail-gmail-padding">
          อีเมล
        </p>
        <v-text-field
          :rules="emailRules"
          v-model="form.email"
          label=""
          single-line
          outlined
          required
          dense
          class="registerform-detail-field"
        ></v-text-field>

        <p class="mb-0 text--bold--14 registerform-detail-password-padding">
          รหัสผ่าน
        </p>
        <v-text-field
          v-model="form.password"
          label=""
          :append-icon="'mdi-eye'"
          single-line
          outlined
          required
          :rules="passwordRules"
          dense
          :type="passwordInputType"
          @click:append="onSwapInputType"
          class="registerform-detail-field"
        ></v-text-field>

        <p
          class="text-center text--regular--14 registerform-detail-accept-padding mb-0"
        >
          ท่านยอมรับ
          <span class="back--text registerform--title text--regular--14"
            >ข้อกำหนดการใช้งาน</span
          >
          และ
          <span class="back--text registerform--title text--regular--14"
            >นโยบายความเป็นส่วนตัว</span
          >
          ของหอศิลป์ฯ เมื่อดำเนินการต่อ
        </p>

        <div v-if="registerStatus">
          <p
            class="mb-0 red--text text--regular--14"
            v-if="registerStatus.success === false"
          >
            {{ registerStatus.message }}
          </p>
        </div>

        <v-container
          cols="12"
          md="6"
          class="pb-0 text-center registerform-login-padding"
        >
          <v-btn
            width="100%"
            rounded
            color="primary"
            light
            elevation="0"
            @click="validate"
            :disabled="!valid"
            class="registerform-btn"
          >
            <span class="registerform-btn-text">สมัครสมาชิก</span></v-btn
          >
        </v-container>

        <v-row align="center" class="loginform-continue-title">
          <v-divider></v-divider>&nbsp;
          <p class="mb-0 ml-3 mr-3 text--regular--14">หรือดำเนินการต่อด้วย</p>
          &nbsp;<v-divider></v-divider>
        </v-row>

        <v-container class="login-btn-social-padding">
          <v-row>
            <v-col cols="6" style="padding-right: 16px;">
              <v-btn
                block
                class="loginform-btn-style"
                outlined
                @click="logInWithFacebook"
              >
                <img
                  src="../assets/fb-logo.png"
                  width="23"
                  height="23"
                  class="loginform-btn-icon-padding"
                />
                <span class="loginform-btn-social-text">Facebook</span>
              </v-btn>
            </v-col>
            <v-col cols="6" style="padding-left: 0px;">
              <a :href="googleLink" style="text-decoration: none;">
                <v-btn block class="loginform-btn-style" outlined>
                  <img
                    src="../assets/gmail-logo.svg"
                    width="22"
                    height="22"
                    class="loginform-btn-icon-padding"
                  />
                  <span class="loginform-btn-social-text">Google</span>
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  created() {
    this.getGoogleUrlLogin();
  },
  computed: {
    ...mapState({
      googleLink: state => {
        return state.authentication.googleLink;
      }
    })
  },
  data: function() {
    let getEmail =
      JSON.parse(
        JSON.stringify(this.$store.state.authentication.registerEmail)
      ) || "";
    return {
      form: {
        fullName: "",
        mobile: "",
        email: getEmail,
        password: ""
      },
      showFuckingMessage: false,
      passwordInputType: "password",
      valid: true,
      nameRules: [v => !!v || "กรุณาระบุ ชื่อ-นามสุกล"],
      phoneNumberRules: [v => !!v || "กรุณาระบุ เบอร์โทรศัพท์"],
      passwordRules: [v => !!v || "กรุณาระบุ รหัสผ่าน"],
      emailRules: [
        v => !!v || "กรุณาระบุ อีเมล",
        v => /.+@.+\..+/.test(v) || "กรุณาระบุ อีเมล ให้ถูกต้อง"
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,
      registerStatus: null
    };
  },
  methods: {
    ...mapActions("user", ["onSignup", "updateUserProfile"]),
    ...mapActions("sendmail", ["sendEMail", "sendEMailWelCome"]),
    ...mapActions("authentication", [
      "login",
      "setShowErrorMessage",
      "getGoogleUrlLogin"
    ]),
    async validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.form.created_date = new Date();
        this.form.updated_date = new Date();
        let response = await this.onSignup({ ...this.form });
        if (response.status === 200) {
          let sendMailObj = {};
          sendMailObj.email = this.form.email;
          let response = await this.sendEMailWelCome(sendMailObj);
          if (response) {
            if (response.status === 200) {
              this.showFuckingMessage = true;
            }
          }
        } else {
          this.registerStatus = Object.assign({}, this.registerStatus, {
            ...response.error,
            success: false
          });
        }
      }
    },
    toLoginForm() {
      this.$emit("clicked", "login");
    },
    async logInWithFacebook() {
      let elementMethod = this;
      if (window.FB) {
        window.FB.login(
          async loginResponse => {
            if (loginResponse.status === "connected") {
              window.FB.api(
                "/me",
                "GET",
                { fields: "id,name,email" },
                async authResponse => {
                  let userInfo = {
                    loginType: "facebook",
                    fullName: authResponse.name,
                    email: authResponse.email,
                    password: authResponse.id
                  };
                  elementMethod.setShowErrorMessage(false);
                  let signUpresponse = await elementMethod.onSignup(userInfo);
                  if (signUpresponse.status === 200) {
                    let sendMailObj = {};
                    sendMailObj.email = signUpresponse.data.email;
                    let response = await this.sendEMailWelCome(sendMailObj);
                    if (response) {
                      if (response.status === 200) {
                        this.showFuckingMessage = true;
                      }
                    }
                  } else {
                    this.registerStatus = Object.assign(
                      {},
                      this.registerStatus,
                      {
                        ...signUpresponse.error,
                        success: false
                      }
                    );
                  }
                }
              );
            }
          },
          {
            scope: "email"
          }
        );
      }
    },
    async registerWithGoogle() {
      let elementMethod = this;
      this.$gAuth
        .signIn()
        .then(async GoogleUser => {
          let userInfo = {
            loginType: "google",
            google: GoogleUser,
            email: GoogleUser.gt.Rt,
            password: GoogleUser.Aa
          };
          elementMethod.setShowErrorMessage(false);
          let signUpresponse = await elementMethod.onSignup(userInfo);
          if (signUpresponse.status === 200) {
            let sendMailObj = {};
            sendMailObj.email = signUpresponse.data.email;
            let response = await elementMethod.sendEMailWelCome(sendMailObj);
            if (response) {
              if (response.status === 200) {
                elementMethod.showFuckingMessage = true;
              }
            }
          } else {
            elementMethod.registerStatus = Object.assign(
              {},
              elementMethod.registerStatus,
              {
                ...signUpresponse.error,
                success: false
              }
            );
          }
        })
        .catch(err => err);
    },
    onSwapInputType() {
      if (this.passwordInputType === "text") {
        this.passwordInputType = "password";
      } else {
        this.passwordInputType = "text";
      }
    }
  }
};
</script>

<style scoped>
.registerform--title {
  text-decoration: underline;
  cursor: pointer;
}
.registerform-btn {
  max-width: 207px !important;
  height: 45px !important;
  border-radius: 23px;
  padding: 12px 68px 12px 65px !important;
}
.registerform-btn-text {
  width: 74px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
}
/* Button */
.loginform-btn-style {
  max-width: 192px !important;
  height: 45px !important;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #ffffff;
  text-transform: capitalize;
  color: #0a0a0a;
}
.loginform-btn-social-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a0a0a;
}
/* Padding */
.registerform-Register-padding {
  padding-top: 18px;
}
.registerform-detail-padding {
  padding-top: 23px;
}
.registerform-detail-contact-number-padding {
  padding-top: 18px;
}
.registerform-detail-gmail-padding {
  padding-top: 18px;
}
.registerform-detail-password-padding {
  padding-top: 15px;
}
.loginform-continue-title {
  margin: 26px 0px 0px 0px;
}
.registerform-detail-accept-padding {
  padding-top: 22px;
}
.registerform-detail-field {
  border-radius: 5px;
  background-color: #ffffff;
  border-color: #afafaf;
  border-width: 1px;
  padding-top: 6px;
  min-height: 45px;
  max-height: 45px;
}
.login-btn-social-padding {
  padding: 18px 0px 61px 0px;
}
.loginform-btn-style {
  border-color: #e5e5e5;
  border-radius: 3px;
  border-width: 1px;
  background-color: #ffffff;
  text-transform: capitalize;
  color: #0a0a0a;
  height: 45px;
}
.loginform-btn-icon-padding {
  margin-right: 12.4px;
}
.registerform-login-padding {
  padding-top: 17px;
}
/* mobile */
@media (max-width: 959px) {
  .registerform-Register-padding {
    padding-top: 24px;
  }
  .registerform-detail-padding {
    padding-top: 15px;
  }
  .registerform-detail-contact-number-padding {
    padding-top: 15px;
  }
  .registerform-detail-gmail-padding {
    padding-top: 15px;
  }
  .registerform-detail-password-padding {
    padding-top: 12px;
  }
  .registerform-detail-accept-padding {
    padding-top: 22px;
  }
  .loginform-continue-title {
    margin: 40.5px 0px 0px 0px;
  }
  .login-btn-social-padding {
    padding: 35px 0px 0px 0px;
  }
  .registerform-login-padding {
    padding-top: 17px;
  }
}
</style>
