import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import axios from "axios";

Vue.use(Router);

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Home
    },
    {
      path: "/login/:param",
      name: "login",
      component: Home
    },
    {
      path: "/rentspacecriteria",
      name: "rentspacecriteria",
      component: () => import("./views/RentSpaceCriteria")
    },
    {
      path: "/bookingprocess",
      name: "bookingprocess",
      component: () => import("./views/BookingProcess")
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About.vue")
    },
    {
      path: "/history",
      name: "reservationshistory",
      component: () => import("./views/ReservationsHistory.vue")
    },
    {
      path: "/history/:param",
      name: "reservationshistory",
      component: () => import("./views/ReservationsHistory.vue")
    },
    {
      path: "/detail",
      name: "reservationshistorydetail",
      component: () => import("./views/ReservationsHistoryDetail.vue")
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("./views/UserProfile.vue")
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("./views/ShoppingCart.vue")
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: () => import("./views/ForgotPassword.vue")
    },
    {
      path: "/forgotpassword/:param",
      name: "forgotpassword",
      component: () => import("./views/ForgotPassword.vue")
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () => import("./views/ResetPassword.vue")
    },
    {
      path: "/resetpassword/:param",
      name: "resetpassword",
      component: () => import("./views/ResetPassword.vue")
    },
    {
      path: "*",
      name: "shop",
      component: () => import("./views/PageNotFound.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        localStorage.removeItem("usertoken");
        next("/login");
      }
      return Promise.reject(error);
    }
  );

  const publicPages = [
    "/login",
    "/forgotpassword",
    "/resetpassword",
    "/register",
    "/forgotpassword/resetpassword"
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("usertoken");

  if (authRequired && !loggedIn) {
    if (to.path !== "/") {
      localStorage.setItem("keeplink", to.path);
    }
    if (publicPages.indexOf(`/${to.name}`) !== -1) {
      return next();
    } else {
      return next("/login");
    }
  }

  next();
});
