import axios from "axios";
import _get from "lodash/get";
import { router } from "../router";

export default {
  async login(form) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/users/login`, form);

      if (response.status === 200) {
        let getToken = _get(response, "data.token", "");
        localStorage.setItem("usertoken", JSON.stringify(getToken));
      }

      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async setActivate(form) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/users/login`, form);

      if (response.status === 200) {
        let getToken = _get(response, "data.token", "");
        localStorage.setItem("usertoken", JSON.stringify(getToken));
      }

      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async getGoogleUrlLogin() {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.get(`${baseUrl}/logingoogle`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async loginGoogle(param) {
    try {
      if (param) {
        // const enCodeFilter = encodeURIComponent(param);
        const baseUrl = process.env.VUE_APP_URL;
        const response = await axios.post(`${baseUrl}/googleauth`, param);
        if (response.status === 200) {
          let getToken = _get(response, "data.token", "");
          localStorage.setItem("usertoken", JSON.stringify(getToken));
        }
        return response;
      }
      if (param.email) {
        localStorage.removeItem("mail");
      }
      return null;
    } catch (error) {
      if (param.email) {
        localStorage.removeItem("mail");
      }
      return error.response.data;
    }
  },
  logout() {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("fullname");
    localStorage.removeItem("logintype");
    router.push("/");
  }
};
