<template>
  <v-app id="app" :style="cssProps">
    <Appbar />

    <!-- <v-main class="white" > -->
    <v-main
      :style="{ backgroundColor: backgroundcolor }"
      class="app--vmain--custom"
    >
      <router-view />
    </v-main>

    <Footer />

    <v-overlay :value="overlayState">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import Footer from "./views/Footer";
import Appbar from "./views/Appbar";

export default {
  data: () => ({
    removeDialog: false
  }),
  computed: {
    cssProps() {
      let themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
    ...mapState({
      overlayState: state => {
        return state.app.overlay;
      },
      // Start : Sasiprapa B. : 2021-04-25
      backgroundcolor: state => {
        return state.app.backgroundcolor;
      }
      // End : Sasiprapa B. : 2021-04-25
    })
  },
  components: { Footer, Appbar }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap");
@import "./assets/css/reset.css";
@import "./assets/css/main.css";

#app {
  font-family: "Kanit", sans-serif;
  color: black;
}

/* @font-face {
  font-family: "Kanit-w600";
  src: url("'https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap'");
} */

@media (max-width: 480px) {
  .app--vmain--custom {
    padding: 64px 0px 0px !important;
  }
}

@media (min-width: 481px) {
  .app--vmain--custom {
    padding: 83px 0px 0px !important;
  }
}
</style>
