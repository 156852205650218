import axios from "axios";

export default {
  async initPage(filters) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/booking-rules?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/booking-rules`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  }
};
