import Vue from "vue";
import Vuex from "vuex";
import cart from "./modules/cart";
import products from "./modules/products";
import login from "./modules/login";
import rentSpaceCriteria from "./modules/rentspacecriteria";
import datespecify from "./modules/datespecify";
import areaspecify from "./modules/areaspecify";
import regulations from "./modules/regulations";
import applicantdetail from "./modules/applicantdetail";
import confirmation from "./modules/confirmation";
import uploadfile from "./modules/uploadfile";
import booking from "./modules/booking";
import app from "./modules/app";
import user from "./modules/user";
import authentication from "./modules/authentication";
import sendmail from "./modules/sendmail";
import exportpdf from "./modules/exportpdf";

// import createLogger from "../plugins/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    app,
    cart,
    products,
    login,
    datespecify,
    areaspecify,
    regulations,
    applicantdetail,
    confirmation,
    uploadfile,
    booking,
    user,
    authentication,
    sendmail,
    exportpdf,
    rentspacecriteria: rentSpaceCriteria
  },
  strict: debug
  // plugins: debug ? [createLogger()] : []
});
