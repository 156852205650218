import uploadFileApi from "../../api/uploadfile";

const state = () => ({
  filesUploaded: null,
  fileImageActivity: []
});

// actions
const actions = {
  async uploadFile({ commit }, files) {
    const result = await uploadFileApi.uploadFile(files);
    commit("uploadFile", result);
    return result;
  },
  setFileImageActivity({ commit }, files) {
    commit("setFileImageActivity", files);
  }
};

// mutations
const mutations = {
  uploadFile(state, result) {
    if (result.status === 200) {
      state.filesUploaded = result.data;
    }
  },
  setFileImageActivity(state, files) {
    state.fileImageActivity = [...files];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
