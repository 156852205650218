import Vue from "vue";
import vuetify from "./plugins/vuetify";
import FullCalendar from "vue-full-calendar";
import VueThreeSixty from "./plugins/vue-360";

import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { currency } from "./currency";
// import { router } from './_helpers';
import axios from "axios";

Vue.config.productionTip = false;

import "fullcalendar/dist/fullcalendar.min.css";
import "vue-360/dist/css/style.css";

Vue.use(VueThreeSixty);
Vue.use(FullCalendar);

axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem("usertoken");
  config.headers.Authorization = token
    ? `Bearer ${token.replace(/['"]+/g, "")}`
    : "";
  return config;
});

let initFacebook = async () => {
  window.fbAsyncInit = function() {
    if (window.FB) {
      window.FB.init({
        appId: "2108162715999898", //You will need to change this
        cookie: true, // Enable cookies to allow the server to access the session.
        xfbml: true, // Parse social plugins on this webpage.
        version: "v10.0",
        status: true
      });
    }
  };
};

let loadFacebookSDK = async (d, s, id) => {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
};

Vue.filter("currency", currency);

loadFacebookSDK(document, "script", "facebook-jssdk");
initFacebook();

import "./assets/css/main.css";

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
