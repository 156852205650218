import login from "../../api/login";
import rentspacecriteriaApi from "../../api/rentspacecriteria";
import { router } from "../../router";

const state = () => ({
  applicantType: "GUEST",
  requestType: "DATE",
  rentSpaceCriteriaData: null,
  applicantSelected: null
});

const getters = {
  rentSpaceCriteriaChecked: state => index => {
    return state.rentSpaceCriteriaData.map((o, i) => {
      return { ...o, checked: i === index };
    });
  }
};

// actions
const actions = {
  async initPage({ commit, dispatch }, filter) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await rentspacecriteriaApi.initPage(filter);
    commit("initPage", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  rentSpaceCriteriaSelected({ commit }, param) {
    login.checkLogin(param, () => commit("rentSpaceCriteriaSelected", param));
  },
  updateCheckedRentSpaceCriteria({ commit }, param) {
    commit("updateCheckedRentSpaceCriteria", param);
  }
};

const mutations = {
  initPage(state, result) {
    if (result.status === 200) {
      if (result.data.length > 0) {
        let assignChecked = result.data.map((o, index) => {
          if (index === 0) {
            return { ...o, checked: true };
          } else {
            return { ...o, checked: false };
          }
        });
        state.rentSpaceCriteriaData = assignChecked;
      } else {
        state.rentSpaceCriteriaData = null;
      }
    } else {
      router.push("/");
    }
  },
  rentSpaceCriteriaSelected(state, param) {
    state.applicantType = param.applicantType;
    state.requestType = param.requestType;
    state.applicantSelected = param.applicantSelected;
    router.push("/bookingprocess");
  },
  updateCheckedRentSpaceCriteria(state, newValue) {
    state.rentSpaceCriteriaData = newValue;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
