import axios from "axios";

export default {
  async uploadFile(formData) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      const response = await axios.post(`${baseUrl}/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return response;
    } catch (error) {
      return null;
    }
  }
};
