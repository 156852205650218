import exportFileAPI from "../../api/exportfileapi";

const state = () => ({
  startDate: null,
  endDate: null,
  areaLists: null,
  isOneDaySelected: false,
  isSubmitDate: false
});

const getters = {};

const actions = {
  async exportFilePDF({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    await exportFileAPI.export(param);
    dispatch("app/setOverlayState", false, { root: true });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
